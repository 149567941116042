.segura__video{
	.video__sobre{
		border: none;
		padding: 0px;
		background-color: transparent;
		position: relative;
		max-width: 730px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		position: relative;
		display: block;

		&:before{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0px;
			left: 0px;
			content: '';
			position: absolute;
			z-index: 25;
			background-image: url('../images/play2.webp');
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center;
			opacity: 1;
			transition: all 0.3s linear;
		}

	}
}
.missao__card{
	text-align: center;

	.missao__icone{
		min-height: 83px;
		margin-bottom: 10px;
	}
	.missao__titulo{
		font-family: 'poppinsbold';
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 22px;
	}
	.missao__desc{
		font-size: 16px;
		line-height: 18px;
		font-family: 'poppinsregular';
	}
}
.segura__interna__sobre{
	padding-top: 96px;
	padding-bottom: 96px;
}
.galeria__grid{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 25px;
	row-gap: 25px;
	max-width: 560px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	a{
		display: block;

		img{
			border-radius: 25px;
		}
	}
}
.interna{
	overflow-x: hidden;
	max-width: 100%;
}
.conheca{
	padding-bottom: 96px;
	position: relative;

	&:before{
		width: 100%;
		height: 2px;
		background: #d82029;
		background: linear-gradient(90deg, rgba(#d82029,0) 0%, rgba(#d94b52,0.7) 25%, rgba(#d94b52,1) 50%, rgba(#d94b52,0.7) 75%,rgba(#d82029,0) 100%);
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0px;
	}


	.sub__secao{
		position: relative;
		max-width: 880px;
		width: 100%;
		margin-bottom: 70px;

		&.active{
			&:after{
				animation: zoomInSwing 1s linear;
			}
		}

		&:after{
			width: 232px;
			height: 128px;
			content: '';
			position: absolute;
			left: calc(100% + 50px);
			top: 52px;
			background-image: url(../images/clique.webp);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center center;
		}
	}
}
.segura__conheca__btn{
	display: flex;
	justify-content: center;
}
.segura__missao{
	padding: 92px 0px 98px 0px;
	position: relative;

	&:after{
		width: 456px;
		height: 439px;
		position: absolute;
		background-image: url(../images/foguete.webp);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
		content: '';
		left: -400px;
		top: 10px;

		@include media-breakpoint-down (md) {
			display: none;
		}
	}

	&.active{
		&:after{
			animation: fadeInDiagoalInversenal 1s linear;

		}
	}
}
.segura__video{
	padding-bottom: 100px;
}
.custom__checkbox{
	padding-top: 0px;

	.custom__check__input{
		position: fixed;
		left: -200vw;

		&:checked{
			~ .custom__check__label{
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.custom__check__label{
		position: relative;
		font-family: 'poppinsregular';
		font-style: italic;
		font-size: 13px;
		line-height: 16px;
		padding-left: 34px;
		cursor: pointer;
		color: #fff;
		min-height: 16px;


		&:after{
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: currentColor;
			content: '';
			position: absolute;
			top: calc(50% - 3px);
			left: 15px;
			opacity: 0;
		}
		&:before{
			width: 16px;
			height: 16px;
			border: 2px solid currentColor;
			border-radius: 50%;
			content: '';
			position: absolute;
			left: 10px;
			top: calc(50%  - 8px);
		}
	}
}
.contato__boneco{
	.lg__boneco{
		min-width: 395px;
		.mao__boneco{
			top: 105px;
			left: 0px;
		}
	}
}
.contato__input{
	display: block;
	width: 100%;
	padding: 20px 26px;
	border-radius: 30px;
	border: none;
	font-size: 14px;
	line-height: 16px;
	color: #d82029;
	background-color: rgba(#d82029, 0.2);
	font-family: 'poppinsregular';
	font-style: italic;
	resize: none;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #d82029;
	}
}
.contato__form{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 14px;
	row-gap: 14px;

	@include media-breakpoint-down (xs) {
		display: flex;
		flex-direction: column;

		.colspan{
			grid-column: unset;
		}
	}

	.form-group{
		margin-bottom: 0px;
	}

	.colspan{
		grid-column: span 2;
	}
}
.segura__politica{
	padding-top: 20px;
	margin-bottom: 50px;
}
.interna__contato{
	padding: 100px 0px 94px 0px;
	position: relative;

	&:after{
		width: 456px;
		height: 439px;
		position: absolute;
		background-image: url(../images/foguete2.webp);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
		content: '';
		right: -300px;
		top: 30px;

		@include media-breakpoint-down (md) {
			display: none;
		}
	}

	&.active{
		&:after{
			animation: fadeInDiagoalInversenalnverse 1s linear;
		}
	}
	.servico__titulo{
		text-align: center;
		max-width: 700px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 50px;
	}
}
.depoimento__interna{
	padding: 100px 0px 60px 0px;

	.servico__titulo{
		text-align: center;
		margin-bottom: 32px;
	}

	.col-lg-6{
		margin-bottom: 40px;
	}
}
.portfolio__interna{
	padding: 94px 0px 40px 0px;

	.servico__titulo{
		max-width: 840px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}
.servico__interna{
	padding: 95px 0px;

	.servico__titulo{
		text-align: center;

		.servico__desc{
			font-weight: normal;
			font-size: 25px;
			line-height: 27px;
		}
	}
}
@keyframes flutuar {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
.servico__img__detalhes{
	animation: flutuar 3s linear infinite;

	text-align: center;
}
.sobre__btn{
	padding-top: 30px;
}
.interna__row__servico{
	align-items: center;

	.col-lg-6{
		margin-bottom: 40px;
	}
}
.btn__center{
	display: flex;
	justify-content: center;
}
.pergunta{
	margin-bottom: 2px;
	color: #d82029;
	font-family: 'poppinsbold';
	font-size: 16px;
	line-height: 18px;
}
.conteudo__gerenciavel{
	.pergunta{
		margin-bottom: 14px;
	}
}
.segura__pergunta{
	margin-bottom: 15px;
}
.contato__form{
	.v-select-output{
		display: block;
		width: 100%;
		height: auto;
		border: none;
		padding: 20px 26px;
		border-radius: 30px;
		border: none;
		font-size: 14px;
		line-height: 16px;
		color: #d82029;
		background-color: rgba(#d82029, 0.2);
		font-family: 'poppinsregular';
		font-style: italic;
		resize: none;

		&:focus{
			outline: none;
		}

		&:focus{
			box-shadow: none;
		}

		&:before{
			margin-top: 4px;
		}
	}
	.v-options{
		font-size: 13px;
		line-height: 15px;
		margin: none;
		max-height: calc(36px * 4);
		border-radius: 0px;
        z-index: 500;
		box-shadow: 0px 0px 5px rgba(#181C27, 0.3);
		background-color: #181C27;

		li{
			color: #d82029;
			font-family: 'poppinsregular';
			transition: all 0.3s linear;

			&.selected{
				background-color: #d82029;
				color: #fff;
			}
			&:not(.selected) {
				&:hover{
					background-color: rgba(#d82029, 0.4);
					color: #fff;
				}
			}
		}
	}
}
// .custom__file{
// 	max-width: 100%;

// 	label{
// 		display: none;
// 	}
// 	input{
// 		height: 42px;
// 	}
.trabalhe__botao{
	padding-top: 30px;
	margin-bottom: 30px;
}
.trabalhe__row{
	align-items: center;
}
.trabalhe__modal__img{
	text-align: center;
	margin: 20px 0px 30px 0px;

	img{
		animation: flutuar 3s linear infinite;
	}
}
.trabalhe__modal__titulo,
.trabalhe__modal___desc{
	max-width: 800px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	color: #fff;
}
.trabalhe__modal__titulo{
	font-family: 'poppinsbold';
	font-size: 38px;
	line-height: 40px;
	margin-bottom: 20px;
}
.trabalhe__modal___desc{
	font-size: 25px;
	line-height: 28px;
}
.lightbox{
	display: flex ;
	flex-direction: column ;

	.lb-dataContainer{
		order: 1;
		padding-bottom: 20px;

		.lb-data{
			.lb-close{
				background: url(../images/fechar.webp) !important;
				width: 50px !important;
				height: 50px !important;
				opacity: 1;
			}
		}

		.lb-details{
			margin-top: 20px;
		}

		.lb-number{
			padding-bottom: 0px;
		}
	}
	.lb-outerContainer{
		order: 2;
	}
}


.select__custom{
	.output{
		height: auto;
		cursor: pointer;
		position: relative;
		display: block;
		width: 100%;
		padding: 20px 40px 20px 26px;
		border-radius: 30px;
		border: none;
		font-size: 14px;
		line-height: 16px;
		color: #d82029;
		background-color: rgba(#d82029, 0.2);
		font-family: 'poppinsregular';
		font-style: italic;


	    &:before{
	    	content: '';
	    	border-left: 0.45em solid transparent;
			border-right: 0.45em solid transparent;
			border-top: 0.5em solid currentColor;
	    	position: absolute;
	    	right: 25px;
			font-style: normal;
	    	top: 50%;
	    	transform: translate(50%,-50%) rotate(var(--rotate, 0));
	    	transition: all 0.3s linear;
	    }
	}

	&.show{
		--rotate: 90deg;
	}

	.dropdown-menu{
		min-width: 180px;
		width: calc(100% - 20px);
		padding-top: 0;
		padding-bottom: 0;
		margin-left: 10px;
		color: #d82029;
		background-color: rgba(#3E1C27, 1);
		overflow: auto;
		max-height: 160px;
		font-size: 14px;
		line-height: 18px;
		box-shadow: 0px 0px 2px rgba(#181C27, 0.3);

		li{
			padding: 10px;
			transition: all 0.3s linear;

			&.selected{
				background: #181C27;
				color: #d82029;
			}

			&:not(.selected) {
				&:not(.disabled):hover{
					background: lighten(#181C27, 10%);
				}
			}


			&:not(.disabled):hover{
				cursor: pointer;
			}

			&.disabled{
				color: rgba(#fff, 0.5);

				&:hover{
					cursor: default;
				}
			}
		}
	}
}

.custom-select-loaded{
	display: none;
}

.md__form__fileOutput{
	display: flex;
	width: 100%;
	color: #000;
	align-items: center;
	background-position: right bottom !important;

	span{
		display: block;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.focus .md__form__fileOutput{
	background-position: left bottom !important;
}
.md__form__inputFile--ready{
	display: none;
}
.md__form__label{
	cursor: pointer;
	color: #d82029;
	background-color: transparent;
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	border-radius: 0px;
	height: 42px;
	font-size: 14px;
	line-height: 17px;
	font-family: 'poppinsregular';
	z-index: 500;
	transition: all 0.3s linear;
	font-style:  italic;
	position: relative;

	&:hover{
		text-decoration: none;
	}

	.detalhe{
		display: flex;
		align-items: center;
		position: relative;
		color: #fff;
		border-radius: 50%;
		width: 42px;
		min-width: 42px;
		height: 42px;
		justify-content: center;
		text-align: center;
		font-size: 20px;
		line-height: 20px;
		margin-right: 10px;
		transition: all 0.3s linear;
		background: #d82029;
		background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
	}
}


.msg__cookies{
	background: #FFF;
	position: fixed;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	padding: 15px;
	border-radius: 15px;
	box-shadow: 0 0 10px rgba(#000,.8);
	width: 100%;
	z-index: 999999999;
	border: 1px solid rgba(#000,.2);

  &.d-none{
    display: none;
  }

	a{
		color: #d82029;
		text-decoration: underline;
	}

	.btn{
		background-color: #d82029;
		font-weight: bold;
    color: #fff;

    &:hover{
      background-color: darken(#d82029, 10%);
    }
	}

	@media (max-width: 767px){
		max-width: calc(100% - 30px);
		font-size: 12px;
		text-align: center;

		a{
			display: inline-block;
		}

		.content{
			margin-bottom: 10px;
		}
	}

  @media (min-width: 767px){
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-column-gap: 10px;
		align-items: center;

		.btn{
			white-space: nowrap;
		}
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		max-width: 768px;
	}

  @media (min-width: 992px) and (max-width: 1199.98px) {
		max-width: 1024px;
	}

  @media (min-width: 1200px) {
		max-width: 1280px;
	}
}

.paginacao{
	display: flex;
	justify-content: center;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 50px;
	padding-bottom: 50px;
	width: 100%;

	.paginacao__item{
		a{
            font-family: 'poppinsbold';
            font-size: 15px;
            line-height: 15px;
            color: #fff;
            display: flex;
            width: 35px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 35px;
            position: relative;
            transition: all 0.3s linear;
			border: 1px solid #fff;
            margin: 0px 2px;
            padding: 0px;
			background-color: transparent;
            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
				color: #fff;
				background: #d82029;
				background: #d82029;
				background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
				border-color: #d82029;
                text-decoration: none;
           }
        }

		&:hover{
			a{
                text-decoration: none;
				background-color: rgba(#d82029, 0.4);
            }
		}
	}
}
