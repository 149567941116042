.rodape{
	background-color: #161c24;
	position: relative;
	color: #fff;

	&:before{
		width: 100%;
		height: 2px;
		background: #d82029;
		background: linear-gradient(90deg, rgba(#d82029,0) 0%, rgba(#d94b52,0.7) 25%, rgba(#d94b52,1) 50%, rgba(#d94b52,0.7) 75%,rgba(#d82029,0) 100%);
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 40;
	}

	.row{
		padding: 84px 15px 84px 15px;
	}

	.container{
		position: relative;
		z-index: 100;
	}

	&:after{
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 30;
		background-image: url('../images/rodapebg.webp');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		content: '';
		bottom: 0px;
		left: 50%;
		transform: translateX(-50%);
		animation: pulsar 3.5s linear infinite;
	}
}
.rodape__card{
	display: flex;
	align-items: center;

	.rodape__card__imagem{
		margin-right: 20px;
		min-width: 81px;
	}
}
.rodape__card__titulo{
	font-family: 'poppinsbold';
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 4px;
}
.rodape__card__links{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.telefone__item{
	span{
		font-family: 'poppinsbold';
	}

	@include media-breakpoint-down (md) {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 5px;
	}
}
.row__linha{
	border-bottom: 1px solid rgba(#fff, 0.2);
}

.rodape__nav{
	padding: 75px 0px;

	ul{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		a{
			transition: all 0.3s linear;
			&:hover{
				color: #d94b52;
				text-decoration: none;
			}
		}

		&:after,
		&:before{
			width: 60px;
			height: 1px;
			background-color: rgba(#fff, 0.2);
			display: block;
			content: '';
		}
		&:after{
			margin-left: 20px;
		}
		&:before{
			margin-right: 20px;
		}
		li{
			&.active{
				a{
					color: #d94b52;
				}
			}
		}
	}
}
.sociais__rodape{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	a{
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		color: #d94b52;
		font-size: 26px;
		line-height: 26px;
		border-radius: 50%;
		transition: all 0.3s linear;
		margin: 5px;
		position: relative;

		@include media-breakpoint-only (lg) {
			width: 44px;
			height: 44px;
		}

		span{
			position: relative;
			z-index: 50;
		}

		&:after{
			width: 100%;
			height: 100%;
			left: -2px;
			top: -2px;
			border-radius: 30px;
			background: #d82029;
			background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
			opacity: 0;
			transition: all 0.3s linear;
			content: '';
			position: absolute;
		}

		&:hover{
			text-decoration: none;
			&:after{
				opacity: 1;
			}
			color: #fff;
		}
	}
}
.selos__rodape{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	img{
		margin: 10px 15px;
		max-width: 136px;
	}
}
.rodape__btns{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;

	.btn__conteudo{
		min-width: 150px;
		margin: 5px;
		height: 64px;
		border-radius: 32px;
	}
}
.assinatura{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 12px;
	line-height: 14px;
	padding: 24px 0px;

	.red{
		font-family: 'poppinssemibold';
		color: #d82029;
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 5px
	}
}
.contato{
	padding: 94px 0px;

	.row{
		align-items: center;

		@include media-breakpoint-down (md){
			.col-lg-8{
				margin-bottom: 30px;
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.rodape__card{
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 30px;

		.rodape__card__imagem{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.rodape__nav{
		ul{
			flex-direction: column;
			align-items: center;

			&:before,
			&:after{
				margin: unset !important;
			}

			& > * {
				margin: 10px !important;
				display: block;
			}

		}
	}
	.sociais__rodape{
		margin-bottom: 30px;
	}
	.selos__rodape{
		margin-bottom: 30px;
	}
}
