@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/poppins-bold/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsextrabold';
    src: url('../fonts/poppins-extrabold/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/poppins-extrabold/Poppins-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsmedium';
    src: url('../fonts/poppins-medium/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/poppins-medium/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/poppins-regular/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'poppinssemibold';
    src: url('../fonts/poppins-semibold/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/poppins-semibold/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

