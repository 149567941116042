.bg__menu{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    animation: fadeIn 0.5s linear;
    z-index: 1050;
    background-color: rgba(#000, 0.7);

    &.hide{
        animation: fadeOut 0.5s linear;
    }
}
.main__logo{
	padding: 15px 0px;
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	font-family: 'poppinsmedium';
	font-size: 14px;
	line-height: 14px;
}
.menu__link{
	&:hover{
		text-decoration: underline;
	}
}
.menu__item{
	&.menu__item--active{
		.menu__link{
			text-decoration: none;
			background: #d82029;
			background: linear-gradient(0deg, #d82029 0%, #d94b52 100%);
			color: #fff;
		}
	}
}
.topo{
	z-index: 1000;
}
.btn__cliente{
	background: #d82029;
	background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
	width: 150px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	color: #fff;
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 14px;

	&:hover{
		text-decoration: none;
		animation: heartBeat2 2s linear infinite;
	}
}
.sociais__nav{
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		width: 38px;
		height: 38px;
		background-color: #fff;
		color: #1a2632;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		font-size: 20px;
		line-height: 20px;
		transition: all 0.3s linear;

		&:hover{
			background-color: #bb2c33;
			color: #1a2632;
			text-decoration: none;
			animation: heartBeat2 2s linear infinite;
		}
	}
}
@include media-breakpoint-up (lg) {
	.topo__main{
		position: relative;
		background-color: #161c25;
		color: #fff;
		border-bottom: 1px solid rgba(#fff, 0.2);

		.container{
			display: flex;
			justify-content: space-between;
		}
	}
	.topo__mobile{
		display: none;
	}
	.topo{
		&.topo--home{
			.topo__main{
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				background-color: transparent;
				border-bottom: unset;
			}
		}
	}
	.main__menu{
		display: flex;
		justify-content: space-between;
		max-width: 600px;
		width: 100%;
		margin: 0px 20px;

		.dropdown__controls{
			display: flex;

			button{
				display: none;
			}
		}
	}
	.main__menu{
		.menu__item{
			display: flex;
			position: relative;
			align-items: center;
		}
	}
	.menu__link{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		transition: all .4s linear;
		border-radius: 30px;
		height: 30px;
		padding-left: 5px;
		padding-right: 5px;
		&:hover{
			text-decoration: none;
			background: #d82029;
			background: linear-gradient(0deg, #d82029 0%, #d94b52 100%);
			color: #fff;
		}
	}
	.segura__botao__topo{
		display: flex;
		align-items: center;

		.btn__cliente{
			margin-right: 5px;
		}
	}
}

@include media-breakpoint-down(lg){
	.menu__link{
		font-size: 12px;
	}
}

@include media-breakpoint-down (md) {
	.segura__botao__topo{
		display: flex;
		flex-direction: column;
		align-items: center;

		.btn__cliente{
			margin-bottom: 20px;
		}
	}
	.topo__main{
		position: fixed;
		left: -250px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		transition: all 0.5s linear;
		background-color: #1a2632;
		z-index: 1080;
		color: #fff;

		.pesquisa__toggler{
			display: none;
		}

		.logo__link{
			display: block;
			text-align: center;
			padding: 5px;
			margin-bottom: 10px;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
	}
	.topo__mobile{
		background-color: #1a2632;
		color: #fff;
		padding: 10px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.mbl__logo{
		max-width: 200px;
		width: 100%;
	}
	.main__logo{
		padding: 10px;
		margin-bottom: 10px;
		text-align: center;
	}
	.mbl__toggler{
		border: none;
		background-color: #fff;
		font-size: 14px;
		line-height: 14px;
		color: #1a2632;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.main__menu{
		margin-bottom: 20px;
	}
	.menu__link{
		padding: 10px 20px;
		margin-bottom: 5px;
		display: block;
		font-size: 14px;
	}
}
