.pseudo__banner{
	padding: 190px 0px 0px 0px;
	background-color: #1a2632;
	position: relative;
	color: #fff;
	overflow: hidden;

	.helper1,
	.helper2,
	.helper3{
		&:before,
		&:after{
			content: '';
			z-index: 15;
			position: absolute;
			background-image: url(../images/estrela.webp);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center;
			width: 38px;
			height: 60px;
			transform-origin: center center;
		}
	}
	.helper1{
		&:after{
			top: 35%;
			right: 20%;
			animation-delay: 2s;
			animation: pulsarEstrela 2s linear infinite;
		}
		&:before{
			top: 75%;
			right: 10%;
			animation: pulsarEstrela 3s linear infinite;
		}
	}
	.helper2{
		@include media-breakpoint-down (sm) {
			display: none;
		}
		&:after{
			top: 80%;
			left: 10%;
			animation-delay: 0s;
			animation: pulsarEstrela 3.5s linear infinite;
		}
		&:before{
			top: 60%;
			left: 40%;
			animation: pulsarEstrela 3s linear infinite;
		}
	}
	.helper3{
		@include media-breakpoint-down (sm) {
			display: none;
		}
		&:after{
			top: 45%;
			left: 16%;
			animation-delay: 2s;
			animation: pulsarEstrela 2s linear infinite;
		}
		&:before{
			top: 10%;
			right: 5%;
			animation-delay: 3s;
			animation: pulsarEstrela 3s linear infinite;
		}
	}

	@include media-breakpoint-down (md) {
		padding-top: 90px;
	}

	.row{
		align-items: center;
		position: relative;
		z-index: 100;

		.col-lg-6{
			&:first-child{
				@include media-breakpoint-up (lg) {
					padding-right: 0px;
				}
			}
		}
	}

	&:after{
		content: '';
		z-index: 10;
		position: absolute;
		background-image: url(../images/bg__banner.webp);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center bottom;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		animation: pulsarFundo 3.5s linear infinite;
	}

	&:before{
		content: '';
		z-index: 15;
		position: absolute;
		background-image: url(../images/estrela.webp);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center;
		width: 38px;
		height: 60px;
		top: 15%;
		left: 15%;
		animation: pulsarEstrela 3s linear infinite;
		animation-delay: 1s;
		transform-origin: center center;
	}

	.container{
		&:after{
			position: absolute;
			left: 0px;
			bottom: 0px;
			width: 100%;
			height: 20%;
			background: rgb(26,38,50);
			background: linear-gradient(180deg, rgba(#181C27,0) 0%, rgba(#181C27,0.5) 48%, rgba(#181C27,1) 100%);
			content: '';
			z-index: 12;
		}
	}
}
.titulo__pseudo{
	font-family: 'poppinsbold';
	font-size: 45px;
	line-height: 47px;
	margin-bottom: 20px;
}
.pseudo__desc{
	font-family: 'poppinsregular';
	font-size: 28px;
	line-height: 30px;
	margin-bottom: 30px;
}
.pseudo__btns{
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.btn__conteudo{
		margin: 6px;
	}
}
.btn__conteudo{
	border: 2px solid #d82029;
	font-family: 'poppinssemibold';
	font-size: 16px;
	line-height: 18px;
	background-color: transparent;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 240px;
	padding: 10px;
	height: 60px;
	border-radius: 30px;
	margin-right: 15px;
	color: #d82029;
	position: relative;
	transition: all 0.3s linear;

	&:hover{
		color: #fff;
		text-decoration: none;
		animation: heartBeat2 2s linear infinite;

		&:after{
			opacity: 1;
		}
	}

	&:after{
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		left: -2px;
		top: -2px;
		border-radius: 30px;
		background: #d82029;
		background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
		opacity: 0;
		transition: all 0.3s linear;
		content: '';
		position: absolute;
	}

	span{
		position: relative;
		z-index: 40;
	}

	.fab{
		font-size: 30px;
		line-height: 30px;
		margin-right: 10px;
	}
}
.mbl__boneco{
	@include media-breakpoint-up (lg) {
		display: none;
	}
	text-align: center;
	margin-bottom: 30px;
 }
 .lg__boneco{
	@include media-breakpoint-down (md) {
		display: none;
	}
	position: relative;
	min-width: 587px;

	.mao__boneco{
		position: absolute;
		top: 208px;
		left: 70px;

		animation: acenando 2s linear infinite;
	}
 }
 .gv8__boneco__modal{
	.lg__boneco{
		max-width: 398px;
		width: 100%;
		min-width: 398px;
		.mao__boneco{
			left: unset;
			top: 120px;
			right: 0px;
		}
	}
 }

 .sobre__flex{
	@include media-breakpoint-up (lg) {
		display: flex;
		align-items: center;

		.gv8__boneco__modal{
			min-width: 398px;
		}
	}
 }
 @keyframes acenando{
	0% {
		transform: rotate(0deg) translate(0,0);
	}
	50%{
		transform: rotate(8deg) translate(5px,-5px);
	}
	100% {
		transform: rotate(0deg) translate(0,0);
	}
}
@keyframes pulsar{
	0% {
		opacity: 1;
	}
	50%{
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}
@keyframes pulsarFundo{
	0% {
		opacity: 0.7;
	}
	50%{
		opacity: 0.3;
	}
	100% {
		opacity: 0.7;
	}

}
@keyframes pulsarEstrela{
	0% {
		opacity: 0;
		scale: 0.5;
	}
	50%{
		opacity: 1;
		scale: 1;
	}
	100% {
		opacity: 0;
		scale: 0.5;
	}
}
@keyframes heartBeat2 {
	0% {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}

	50% {
	  -webkit-transform: scale(1.1);
	  transform: scale(1.1);
	}

	100% {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}
  }
  @keyframes heartBeat3 {
	0% {
	  -webkit-transform: scale(0.9);
	  transform: scale(0.9);
	}

	50% {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}

	100% {
	  -webkit-transform: scale(0.9);
	  transform: scale(0.9);
	}
  }
.sobre{
	background-color: #181C27;
	color: #fff;
	padding: 70px 0px 90px 0px;

	.container{
		position: relative;
		z-index: 500;
	}
}
.sobre__selos{
	display: flex;
	margin-bottom: 40px;
	justify-content: space-between;
	flex-wrap: wrap;

	.selo{
		width: 50%;
		display: flex;
		align-items: center;
		justify-content:  center;
		padding-top: 5px;
		padding-bottom: 5px;

		img{
			max-width: 170px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}

		&:nth-child(1){
			justify-content: center;
			border-right: 1px solid rgba(#fff, 0.2);
		}

		&:nth-child(3){
			justify-content: center;
			border-right: 1px solid rgba(#fff, 0.2);
		}
	}
}
.sobre__desc{
	margin-bottom: 30px;
}
.titulo__secao{
	span{
		display: inline-block;
		padding: 10px 20px;
		border-radius: 20px;
		background-color: rgba(#d82029,0.2);
		color: #d82029;
		font-size: 14px;
		line-height: 14px;
		margin-bottom: 15px;
	}
}
.sub__secao{
	font-family: 'poppinsbold';
	font-size: 38px;
	line-height: 42px;
	color: #fff;
	margin-bottom: 15px;

	@include media-breakpoint-down (md) {
		font-size: 24px;
		line-height: 28px;
	}
}
.sobre{
	.col-lg-6{
		&.active{
			&:after{
				animation: zoomIn 0.5 linear;
			}

			&:before{
				animation: zoomIn 0.5 linear;
			}

		}
	}
	.col-lg-6{
		&:nth-child(2) {
			&:after,
			&:before{
				width: 57px;
				height: 57px;
				position: absolute;
				background-image: url(../images/detalhe.webp);
				background-repeat: no-repeat;
				background-size: auto;
				background-position: center center;
				content: '';
				z-index: 50;
			}
			&:after{
				top: 90px;
				right: -20px;
			}
			&:before{
				bottom: 0px;
				right: 40px;
			}
		}
	}
}
.imagem__sobre{
	position: relative;

	&:before{
		width: 57px;
		height: 57px;
		position: absolute;
		background-image: url(../images/detalhe2.webp);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
		animation: heartBeat2 2s linear infinite;
		content: '';
		z-index: 50;
		top: 50px;
		left: -30px;
	}

	&:after{
		width: 730px;
		height: 730px;
		position: absolute;
		top: calc(50% - 365px);
		left: calc(50% - 365px);
		background-image: url(../images/sombra__note.webp);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
		animation: heartBeat3 2s linear infinite;
		content: '';
		z-index: -1;
	}
}
.numero__card{
	text-align: center;
	margin-bottom: 20px;

	.numero__card__imagem{
		min-height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;
	}
	.numero__card__numero{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 70px;
		line-height: 70px;
		font-family: 'poppinsbold';

		.counter,
		.counter-ready{
			position: relative;

			&:before{
				width: 39px;
				height: 46px;
				background-image: url('../images/mais.webp');
				background-repeat: no-repeat;
				background-size: auto;
				background-position: center center;
				content: '';
				position: absolute;
				left: -39px;
				top: calc(50% - 23px);
			}
		}
	}
}
.numero__card__titulo{
	font-family: 'poppinssemibold';
	font-size: 18px;
	line-height: 20px;
}
.numeros{
	padding-bottom: 30px;
}
.servico__card{
	display: flex;
	max-width: 350px;
	width: 100%;
	padding: 10px;
	min-height: 350px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	border: 2px solid #d82029;
	background-color: rgba(#d82029, 0.3);
	border-radius: 70px;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&:hover{
		text-decoration: none;
		&:after{
			top: -400px;
		}
	}

	&:after{
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		top: -2px;
		left: -2px;
		position: absolute;
		content: '';
		background: #181C27;
		background: linear-gradient(0deg, rgba(#181C27,0) 0%, rgba(#181C27,0.8) 70%, rgba(#181C27,1) 100%);
		transition: all 1s linear;
	}

	.servico__card__icone,
	.servico__card__titulo,
	.servico__lista{
		position: relative;
		z-index: 50;
	}
}
.servico__card__icone{
	min-height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
}
.servico__card__titulo{
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	height: 48px;
	margin-bottom: 5px;
	text-align: center;
}
.servico__lista{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;

	li{
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		&:before{
			content: '';
			display: block;
			width: 20px;
			height: 16px;
			margin-right: 5px;
			background-image: url('../images/check.webp');
			background-repeat: no-repeat;
			background-size: auto;
			min-width: 20px;
			background-position: center center;
		}
	}
}
.servico__grid{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 25px;
	row-gap: 25px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-down (xs) {
		grid-template-columns: repeat(1, 1fr);
	}
}
.servicos{
	padding-top: 100px;
	position: relative;

	.container{
		position: relative;
		&:after{
			width: 456px;
			height: 439px;
			position: absolute;
			background-image: url(../images/foguete.webp);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center center;
			content: '';
			left: -400px;
			top: 0px;

			@include media-breakpoint-down (md) {
				display: none;
			}
		}
	}

	&.active{
		.container{
			&:after{
				animation: fadeInDiagoalInversenal 1s linear;
			}
		}
	}
	.servico__titulo{
		text-align: center;
		margin-bottom: 15px;
	}
	.container{
		padding-bottom: 90px;
		position: relative;

		&:before{
			width: 100%;
			height: 2px;
			background: #d82029;
			background: linear-gradient(90deg, rgba(#d82029,0) 0%, rgba(#d94b52,0.7) 25%, rgba(#d94b52,1) 50%, rgba(#d94b52,0.7) 75%,rgba(#d82029,0) 100%);
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0px;
		}
	}
}
.portifolio{
	padding-top: 90px;

	.servico__titulo{
		text-align: center;
		margin-bottom: 35px;
	}
}
.servico__desc{
	font-size: 25px;
	line-height: 29px;
	margin-bottom: 40px;
	text-align: center;
}
.segura__servico{
	max-width: 350px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
}
@keyframes fadeInDiagoalInversenal{
    0%{
        transform: translate(-1000px,600px);
        opacity:0;
    }
    100%{
        transform: translate(0,0);
        opacity:1;
    }
}

@keyframes fadeInDiagoalInversenalnverse{
    0%{
        transform: translate(1000px,600px);
        opacity:0;
    }
    100%{
        transform: translate(0,0);
        opacity: 1;
    }
}
.responsivo{
	padding: 82px 0px;
}

.responsivo__grid{
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	position: relative;
	margin-bottom: 40px;

	&.active{
		&:after{
			animation: zoomInSwing 1s linear;
		}
	}

	&:after{
		width: 283px;
		height: 128px;
		content: '';
		position: absolute;
		left: calc(100% - 200px);
		top: -80px;
		background-image: url(../images/funciona.webp);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
	}

	& > * {
		padding: 0px 15px;
	}

	@include media-breakpoint-down (md) {
		flex-direction: column;

		& > * {
			margin-bottom: 20px;
		}
		.responsivo__detalhe{
			order: 1;
		}
		.responsivo__card{
			order: 2;
		}
	}

}
.responsivo__card__imagem{
	margin-bottom: 16px;
}
.responsivo__card__titulo{
	font-family: 'poppinsregular';
	opacity: 0.6;
}
.responsivo__header{
	max-width: 800px;
	width: 100%;
	margin-bottom: 56px;

	@include media-breakpoint-down (md) {
		margin-bottom: 100px;
	}
}
@keyframes zoomInSwing {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
	60% {
	  -webkit-transform: rotate3d(0, 0, 1, 15deg);
	  transform: rotate3d(0, 0, 1, 15deg);
	}

	70% {
	  -webkit-transform: rotate3d(0, 0, 1, -10deg);
	  transform: rotate3d(0, 0, 1, -10deg);
	}

	80% {
	  -webkit-transform: rotate3d(0, 0, 1, 5deg);
	  transform: rotate3d(0, 0, 1, 5deg);
	}

	90% {
	  -webkit-transform: rotate3d(0, 0, 1, -5deg);
	  transform: rotate3d(0, 0, 1, -5deg);
	}

	to {
	  -webkit-transform: rotate3d(0, 0, 1, 0deg);
	  transform: rotate3d(0, 0, 1, 0deg);
	}
  }
.detalhe__responsivo__titulo{
	font-family: 'poppinsmedium';
	color: #d82029;
	font-size: 16px;
	line-height: 18px;
}
.mgb20{
	margin-bottom: 20px;
}
.mgt20{
	margin-top: 20px;
}
.responsivo__subtitulo{
	font-family: 'poppinsbold';
	font-size: 38px;
	line-height: 40px;
	color: #fff;
	margin-top: 46px;
	text-align: center;
}
.responsivo{
	border-bottom: 2px solid rgba(#fff, 0.2);
	padding-bottom: 90px;
	position: relative;

	.container{
		position: relative;
		z-index: 50;
	}
	&:after{
		position: absolute;
		bottom: 0px;
		left: 50%;
		transform: translateX(-50%);
		height: 567px;
		background-image: url('../images/bg__responsivo.webp');
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center bottom;
		content: '';
		z-index: 30;
		width: 100%;
	}
}
.portifolio__card{
	display: block;
	max-width: 480px;
	width: 100%;
	position: relative;
	overflow: hidden;

	&:hover{
		text-decoration: none;
		.portifolio__card__conteudo{
			opacity: 1;
		}
		@include media-breakpoint-up (lg) {
			.portifolio__card__imagem{
				img{
					transform: scale(1.2);
				}
			}
		}
	}

	.portifolio__card__conteudo{
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: center;
		background-color: rgba(#161c25, 0.8);
		transition: all 0.3s linear;

		@include media-breakpoint-up (lg) {
			position: absolute;
			top: 0px;
			left: 0px;
			opacity: 0;
			width: 100%;
			height: 100%;
		}
	}
	img{
		transition: all 0.5s linear;
	}
}
.portifolio__grid{
	display: flex;
	justify-content: center;
	position: relative;
	flex-wrap: wrap;

	& > *{
		width: 25%;
		display: block;

		@include media-breakpoint-down (sm) {
			width: 50%;
		}
	}


}
.portifolio__card__tipo{
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 18px;
	color: #d82029;
}
.portifolio__card__nome{
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	margin-bottom: 20px;
}
.portifolio__card__btn{
	width: 170px;
	height: 36px;
	border-radius: 18px;
	font-family: 'poppinssemibold';
	font-size: 16px;
	line-height: 16px;
	background: #d82029;
	background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
	display: flex;
	align-items: center;
	justify-content: center;
}
.portifolio__categorias{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 30px;

	button{
		border: 2px solid #d82029;
		font-family: 'poppinssemibold';
		font-size: 16px;
		line-height: 18px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 170px;
		padding: 5px 10px;
		height: 36px;
		border-radius: 30px;
		margin-right: 15px;
		color: #d82029;
		position: relative;
		transition: all 0.3s linear;
		background-color: transparent;
		margin: 15px;

		&.active{
			color: #fff;

			&:after{
				opacity: 1;
			}
		}

		&:hover{
			color: #fff;
			text-decoration: none;
			animation: heartBeat2 2s linear infinite;

			&:after{
				opacity: 1;
			}
		}

		&:after{
			width: calc(100% + 4px);
			height: calc(100% + 4px);
			left: -2px;
			top: -2px;
			border-radius: 30px;
			background: #d82029;
			background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
			opacity: 0;
			transition: all 0.3s linear;
			content: '';
			position: absolute;
		}

		span{
			position: relative;
			z-index: 40;
		}
	}
}
.vantagens{
	padding: 92px 0px 0px 0px;

	.container{
		position: relative;
		padding-bottom: 92px;

		.helper{
			&:after,
			&:before{
				width: 57px;
				height: 57px;
				position: absolute;
				background-repeat: no-repeat;
				background-size: auto;
				background-position: center center;
				content: '';
				z-index: 50;
			}
			&:after{
				background-image: url(../images/detalhe.webp);
				right: -70px;
				top: 700px;
			}
			&:before{
				background-image: url(../images/detalhe2.webp);
				left: -70px;
				bottom: 350px;
			}
		}

		&:after{
			width: 456px;
			height: 439px;
			position: absolute;
			background-image: url(../images/foguete2.webp);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center center;
			content: '';
			right: -400px;
			top: 40px;

			@include media-breakpoint-down (md) {
				display: none;
			}
		}

		&:before{
			width: 100%;
			height: 2px;
			background: #d82029;
			background: linear-gradient(90deg, rgba(#d82029,0) 0%, rgba(#d94b52,0.7) 25%, rgba(#d94b52,1) 50%, rgba(#d94b52,0.7) 75%,rgba(#d82029,0) 100%);
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0px;
		}

		&.active{
			&:after{
				animation: fadeInDiagoalInversenalnverse 1s linear;
			}
			.helper{
				&:after,
				&:before{
					animation: zoomIn 1s linear;
				}
			}
		}
	}

	.servico__titulo{
		text-align: center;
		margin-bottom: 30px;
	}
}
.row__custom{
	display: flex;
	align-items: center;
	justify-content: center;
	.info__desc{
		width: 100%;
		display: block;
		max-width: calc(100% - 52px);
	}

	@include media-breakpoint-down (md) {
		flex-direction: column;

		.col__info{
			order: 1;
		}
		.col__imagem{
			order: 2;
		}

		.info__card{
			max-width: unset;
			margin-bottom: 20px;
		}
	}

	@include media-breakpoint-up (lg) {
		.col__info{
			width: 28%;

			&:nth-child(3) {
				.info__numero{
					margin-right: 0px;
					margin-left: 10px;
					order: 2;
				}
				.info__desc{
					order: 1;
					text-align: right;
				}
			}
		}
		.col__imagem{
			width: 44%;
		}
	}

	.col__imagem{
		text-align: center;
	}
}
.info__card{
	display: flex;
	align-items: flex-start;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 100px;
	max-width: 300px;

	.info__numero{
		font-family: 'poppinsbold';
		font-size: 40px;
		line-height: 44px;
		color: #d44752;
		margin-right: 10px;
	}
}
.time{
	padding: 96px 0px 82px 0px;
	position: relative;

	&.active{
		.container{
			&:after{
				animation: bounceIn 1s linear;
			}
		}
	}

	.container{
		position: relative;
		z-index: 200;
		&:after{
			width: 57px;
			height: 57px;
			position: absolute;
			background-image: url(../images/detalhe.webp);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center center;
			content: '';
			z-index: -1;
			top: 0px;
			right: 0px;
		}
	}

	.time__header{
		margin-bottom: 100px;
	}
}
.owl-carousel{
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute !important;
			top: 50% !important;
			transform: translateY(-50%) !important;
			padding: 0px !important;
			border: none !important;
			background-color: transparent !important;
			color: #fff !important;
			font-size: 40px !important;
			line-height: 40px !important;
			transition: all 0.3s linear !important;

			&:hover{
				color: #d82029 !important;
			 }
		}
		button.owl-prev{
			left: -15px !important;

			@media (min-width: 1250px)  {
				left: -35px !important;
			}
		}
		button.owl-next{
			right: -15px !important;

			@media (min-width: 1250px)  {
				right: -35px !important;
			}
		}
	}
}
.time__card{
	max-width: 166px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	text-align: center;
	min-height: 270px;

	&:hover{
		.time__card__imagem{
			&:before{
				top: -300px;
			}
		}
		.time__card__links{
			opacity: 1;
		}
	}

	.time__card__imagem{
		position: relative;
		border-radius: 50%;
		max-width: 160px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 3px;
		background-color: #fff;
		margin-bottom: 15px;
		img{
			position: relative;
			z-index: 10;
		}
		&:after{
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			top: -3px;
			left: -3px;
			content: '';
			position: absolute;
			z-index: 2;
			background-color: #d82029;
			border-radius: 50%;
		}
		&:before{
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			top: -3px;
			left: -3px;
			position: absolute;
			content: '';
			background: #181C27;
			background: linear-gradient(0deg, rgba(#181C27,0) 0%, rgba(#181C27,0.8) 70%, rgba(#181C27,1) 100%);
			transition: all 1s linear;
			border-radius: 50%;
			z-index: 3;
		}
		.time__card__links{
			position: absolute;
			bottom: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			z-index: 50;
			transition: all 0.3s linear;

			@include media-breakpoint-up (lg) {
				opacity: 1;
			}

			a{
				width: 34px;
				height: 34px;
				background-color: #fff;
				color: #161c25;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				transition: all 0.3s linear;
				font-size: 16px;
				line-height: 16px;
				margin: 0px 3px;

				&:hover{
					background-color: #bb2c33;
					color: #161c25;
					text-decoration: none;
				}
			}
		}
		img{
			border-radius: 50%;
			position: relative;
		}
	}
}
.time__interna{
	padding-bottom: 80px;
	border-top: 1px solid rgba(#fff, 0.2);

	.container{
		&:after{
			display: none;
		}
	}
}
.contato__interna__secao{
	border-top: 1px solid rgba(#fff, 0.2);
}
.time__card__titulo{
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 10px;
}
.time__card__cargo{
	color: #d82029;
	font-size: 14px;
	line-height: 16px;
}
.time__header{
	max-width: 700px;
	width: 100%;
	margin-bottom: 50px;
}
.segura__time__owl{
	position: relative;

	&.active{
		&:after{
			animation: zoomInSwing 1s linear;
		}
	}

	&:after{
		width: 227px;
		height: 128px;
		content: '';
		position: absolute;
		left: calc(100% - 140px);
		top: -80px;
		background-image: url(../images/funciona2.webp);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
	}
}
.depoimentos{
	background-image: url(../images/depbg.jpg);
	background-size: auto;
	background-position: center center;
	background-repeat: repeat-y;
	background-attachment: fixed;
	padding: 100px 0px 110px 0px;

	.servico__titulo{
		text-align: center;
		margin-bottom: 10px;
	}
	.titulo__secao{
		margin-bottom: 20px;
	}

	.dep__secao__desc{
		max-width: 750px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
		font-size: 25px;
		line-height: 28px;
		text-align: center;
		font-family: poppinsregular;
	}
}
.depoimentos__owl.owl-carousel{
	.owl-stage{
		margin-left: auto;
		margin-right: auto;
	}
	.owl-nav{
		@include media-breakpoint-up (lg) {
			display: none;
		}
		@include media-breakpoint-down (md) {
			display: flex;
			justify-content: center;
			padding-top: 30px;

			button{
				position: relative;
				transform: unset;
			}


		}
		&.disabled{
			display: none !important;
		}
	}
	.owl-dots{
		display: flex;
		justify-content:  center;
		align-items: center;
		padding-top: 40px;

		@include media-breakpoint-down (md) {
			display: none;
		}
	}
	.owl-dot{
		width: 16px;
		height: 16px;
		border: 2px solid #bb2c33;
		transition: all 0.3s linear;
		border-radius: 50%;
		background-color: transparent;
		margin: 0px 5px;

		&.active{
			background-color: #bb2c33;
		}
	}
	&.alt{
		.owl-dots{
			display: none;
		}
		.owl-nav{
			display: block;


		}
	}
}
.depoimentos__card{
	overflow: hidden;
	border-radius: 20px 20px 0px 0px;
	display: block;
	max-width: 541px;
	margin-left: auto;
	margin-right: auto;

	.depoimento__video{
		padding: 0px;
		background-color: transparent;
		border: none;
		margin: 3px;
		margin-bottom: 15px;
		position: relative;
		border-radius: 20px;

		.depoimento__video__img{
			&:after{
				width: 100%;
				height: 100%;
				background-color: rgba(#161c25, 0.3);
				top: 0px;
				left: 0px;
				content: '';
				position: absolute;
				opacity: 0;
				z-index: 20;
				transition: all 0.3s linear;
			}
			&:before{
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				top: 0px;
				left: 0px;
				content: '';
				position: absolute;
				z-index: 25;
				background-image: url('../images/play.webp');
				background-repeat: no-repeat;
				background-size: auto;
				background-position: center;
				opacity: 0;
				transition: all 0.3s linear;
			}
		}

		img{
			border-radius: 20px;
		}

		&:hover{
			&:before{
				top: -300px;
			}
			.depoimento__video__img{
				&:after,
				&:before{
					opacity: 1;
				}
			}
		}

		img{
			position: relative;
			z-index: 10;
		}
		&:after{
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			top: -3px;
			left: -3px;
			content: '';
			position: absolute;
			z-index: 2;
			background-color: #d82029;
			border-radius: 20px;
		}
		&:before{
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			top: -3px;
			left: -3px;
			position: absolute;
			content: '';
			background: #181C27;
			background: linear-gradient(0deg, rgba(#181C27,0) 0%, rgba(#181C27,0.8) 70%, rgba(#181C27,1) 100%);
			transition: all 1s linear;
			border-radius: 20px;
			z-index: 3;
		}
	}
}
.depoimento__titulo{
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 5px;
}
.depoimento__info{
	display: flex;
	align-items: center;
	color: #bb2c33;
}
.modal__boneco{
	.modal-dialog{
		max-width: 1110px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.modal-content{
		background-color: transparent;
		border: none;

		.modal-header{
			border: none;
			display: flex;
			justify-content: flex-end;

			button{
				background: #d82029;
				background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
				color: #fff;
				border: none;
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 25px;
				line-height: 25px;
				border-radius: 50%;

				&:hover{
					animation: heartBeat2 1s linear;
				}
			}
		}

		.modal-body{
			background-image: url('../images/modalbg.webp');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border-radius: 30px;
			overflow: hidden;
			padding: 60px;

			@include media-breakpoint-down (md) {
				padding: 20px;
			}
		}
	}
}
.gv8__boneco__texto{
	padding-left: 30px;
	.gv8__modal__titulo{
		font-family: 'poppinsbold';
		font-size: 80px;
		line-height: 84px;
		position: relative;
		padding-bottom: 30px;
		margin-bottom: 20px;
		color: #fff;

		@include media-breakpoint-down (md) {
			font-size: 30px;
			line-height: 34px;
		}

		&:after{
			width: 145px;
			height: 18px;
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0px;
			background-color: #fff;
			border-radius: 9px;
		}
	}
	.gv8__modal__desc{
		color: #fff;
		font-size: 25px;
		line-height: 29px;

		@include media-breakpoint-down (md) {
			font-size: 20px;
			line-height: 24px;
		}
	}
}
@include media-breakpoint-down (md) {
	.sobre{
		.col-lg-6{
			&:nth-child(2) {
				order: 1;
				margin-bottom: 20px;
			}
			&:nth-child(1) {
				order: 2;
			}
		}
	}
}
.modal__custom{
	.modal-dialog{
		max-width: 1100px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;

		.modal-content{
			background-color: transparent;
			border: none;
		}
		.modal-header{
			border: none;
			display: flex;
			justify-content: flex-end;

			button{
				background: #d82029;
				background: linear-gradient(0deg, rgba(#d82029,1) 0%, rgba(#d94b52,1) 100%);
				color: #fff;
				border: none;
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 25px;
				line-height: 25px;
				border-radius: 50%;

				&:hover{
					animation: heartBeat2 1s linear;
				}
			}
		}
	}
}
.galeria__listagem{
	padding-top: 30px;
	a{
		display: block;
		max-width: 255px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		border-radius: 40px;
		overflow: hidden;

		img{
			border-radius: 40px;
		}
	}
	.col-lg-3{
		margin-bottom: 30px;
	}
}
.depoimento__card__novo{
	padding: 4px 3px 0px 3px;
	position: relative;
	border: none;
	display: block;
	max-width: 256px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background-color: transparent;
	overflow: hidden;
	text-align: center;

	.depoimento__card__novo__titulo{
		color: #fff;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 3px;
	}

	&:hover{
		.depoimento__card__novo__img{
			&:before{
				top: -300px;
			}
		}
	}

	.depoimento__card__novo__cargo{
		color: #bb2c33;
		font-size: 14px;
		line-height: 16px;
	}

	.depoimento__card__novo__img{
		position: relative;
		margin-bottom: 20px;

		.helper{
			&:after{
				width: 57px;
				height: 57px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-image: url(../images/play3.png);
				background-repeat: no-repeat;
				background-size: auto;
				background-position: center;
				content: '';
				z-index: 100;
			}
		}

		img{
			position: relative;
			z-index: 10;
		}
		&:after{
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			top: -3px;
			left: -3px;
			content: '';
			position: absolute;
			z-index: 2;
			background-color: #d82029;
			border-radius: 20px;
		}
		&:before{
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			top: -3px;
			left: -3px;
			position: absolute;
			content: '';
			background: #181C27;
			background: linear-gradient(0deg, rgba(#181C27,0) 0%, rgba(#181C27,0.8) 70%, rgba(#181C27,1) 100%);
			transition: all 1s linear;
			border-radius: 20px;
			z-index: 3;
		}
	}
}
